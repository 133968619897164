function isDate(pVal)
{
    var reTipo = /^((0[1-9]|[12]\d)\/(0[1-9]|1[0-2])|30\/(0[13-9]|1[0-2])|31\/(0[13578]|1[02]))\/\d{4}$/;
    return reTipo.test(pVal);
}

function datetimeSort(a,b,order){

    var dataA = a.split(" ");
    var dataB = b.split(" ");

    var timeA = dataA[1];
    var timeB = dataB[1];

    var a = dataA[0].split("/");
    var b = dataB[0].split("/");

    var anoA = parseInt(a[2],10);
    var anoB = parseInt(b[2],10);
    var mesA = parseInt(a[1],10);
    var mesB = parseInt(b[1],10);
    var diaA = parseInt(a[0],10);
    var diaB = parseInt(b[0],10);

    var h = timeA.split(':');
    //alert(h +' - '+ h[1] +' - '+ parseInt(h[1],10));
    var y = timeB.split(':');

    var secA = parseInt(h[2],10);
    var secB = parseInt(y[2],10);
    var minA = parseInt(h[1],10);
    var minB = parseInt(y[1],10);

    var horaA = parseInt(h[0],10);
    var horaB = parseInt(y[0],10);

    if ( anoA == anoB ){
        if ( mesA == mesB ){
            if ( diaA == diaB ){
                if ( horaA == horaB ){
                    if ( minA == minB ){
                        return parseInt( secA > secB ? 1:-1 ) * parseInt(order=="asc" ? 1:-1);
                    } else {
                        return parseInt( minA > minB ? 1:-1 ) * parseInt(order=="asc" ? 1:-1);
                    }
                } else {
                    return parseInt( horaA > horaB ? 1:-1 ) * parseInt(order=="asc"? 1 :-1);
                }
            } else {
                return parseInt( diaA > diaB ? 1:-1 ) * parseInt(order=="asc"? 1 :-1);
            }
        } else {
            return parseInt( mesA > mesB ? 1:-1 ) * parseInt(order=="asc"? 1 :-1);
        }
    } else{
        return parseInt( anoA > anoB ? 1:-1 ) * parseInt(order=="asc"? 1 :-1);
    }
}

//alert( datetime_custom('22/02/1921 02:10:45','22/02/1921 01:10:45','asc') );

function dateSort(a,b,order){

    var a = a.split("/");
    var b = b.split("/");

    var a2 = parseInt( a[2], 10 );
    var b2 = parseInt( b[2], 10 );
    var a1 = parseInt( a[1], 10 );
    var b1 = parseInt( b[1], 10 );
    var a0 = parseInt( a[0], 10 );
    var b0 = parseInt( b[0], 10 );

    if ( a2 == b2 ){
        if ( a1 == b1 )
            return parseInt( a0 > b0 ? 1 : -1 )*( order == "asc" ? 1 : -1 );
        else
            return parseInt( a1 > b1 ? 1 : -1 )*( order == "asc" ? 1 : -1 );
    } else
        return parseInt( a2 > b2 ?1:-1)*(order=="asc"?1:-1);
}